<template>
  <div class="page mb-5">
    <header class="container border mt-2">
      <div class="d-flex flex-row align-items-center">
        <div class="col-auto mx-2">
          <!-- Replace 'logo.png' with your logo's path -->
          <img src="../assets/logo.png" alt="Lyt-Soft Logo" style="height: 100px;">
        </div>
        <div class="d-flex flex-column">
          <h2 class="fw-bold text-info mb-0">Lyt-Soft POS</h2>
          <span class="lead mb-0">Powered By <a href="https://chrilantech.com">Chrilan Technology</a></span>
          <span class="text-muted">Empowering your business, one transaction at a time.</span>
        </div>
      </div>
    </header>
    <section class="container d-flex flex-column mt-4" v-if="reference && !reference.processed">
      <h3 class="mb-3">Subscription Renewal</h3>
      <span class="fs-5"><strong>Customer: {{ reference.debtor_name }}</strong></span>
      <span>Your Point Of Sale service subscription is due for renewal. The scheduled payment of <strong>{{
          $utils.formatMoney(reference.amount)
        }}</strong> secures continuous service.</span>
      <span class="text-secondary">Review your invoice and verify charges <a
          :href="`https://chrilantech.com/LytSoftPosOnline/api/PDFReport/getInvoiceFromSaleNumber/${reference.salenum}/burntofi_chrilan`">here</a>.</span>
      <p>Select your preferred payment method below. Contact us for any assistance.</p>

      <div class="mt-3" v-if="selectedPaymentMethod === null">
        <h4>Select a Payment Method:</h4>
        <button :disabled="showLoadingText" class="btn btn-outline-primary m-2" @click="selectPaymentMethod('EFT')">
          <img src="../assets/classic.jpg" height="25px" alt="">
          Bank / EFT
        </button>
        <button :disabled="showLoadingText" class="btn btn-outline-info m-2" @click="selectPaymentMethod('Kazang')">
          <span v-if="showLoadingText && selectedPaymentMethod === `Kazang`" class="spinner-grow spinner-grow-sm"
                aria-hidden="true"></span>
          <img src="../assets/Kazang.png" height="25px" alt="">
          Mobile Money
        </button>
        <button :disabled="showLoadingText" class="btn btn-outline-success m-2"
                @click="selectPaymentMethod('Kazang VCD')">
          <img src="../assets/Kazang.png" height="25px" alt="">
          Kazang Vendor Deposit
        </button>
      </div>
      <div class="mt-3" v-else>
        <h5 class="text-center my-2 text-primary">
          <a @click="selectedPaymentMethod = null" class="btn btn-primary btn-sm text-white"
             v-if="!reference.payment_type && selectedPaymentMethod !== `Kazang`">
            <img src="../assets/icons/rotate-ccw.svg" alt=""/>
          </a>
          Payment Method : {{ selectedPaymentMethod }}
        </h5>
        <div v-if="selectedPaymentMethod === `EFT`">
          <div class="card">
            <div class="card-body">
              <div class="text-center mb-3">
                <h5>Payment Reference: {{ reference.payment_reference }}</h5>
                <button class="btn btn-sm btn-secondary" @click="copyReference">
                  Copy Reference
                </button>
              </div>
              <h5 class="card-subtitle mb-2">BANK Details:</h5>
              <span>
                Chrilan Technology Limited
                Bank: FNB <br>
                Business Cheque Account<br>
                Acc number: 62812689521<br>
                Branch name: Commercial<br>
                B/c: 260001<br>
                Swift code: FIRNZMLX <br>
                <br>
                Note : We would like to remind you that when making an EFT payment, it is very
                important
                to use the
                correct reference.
                This ensures that your payment is correctly applied to your account and prevents any
                delays in
                processing.
                Please make sure to double check the reference number before making your EFT
                payment.
                Thank you for choosing our EFT payment option and if you have any questions or
                concerns,
                please do
                not hesitate to reach out to us.
              </span>
            </div>
          </div>
        </div>

        <div v-if="selectedPaymentMethod === `Kazang VCD`">
          <div v-if="showLoadingText">
            <span class="spinner-grow spinner-grow-sm" aria-hidden="true"></span>
            Please wait
          </div>
          <div v-else>
            <h6 class="text-center fw-bold">Select Payment Account</h6>
            <div class="list-group">
              <a v-for="account in kazangAccounts" @click="payByKazangVCD(account)"
                 class="list-group-item list-group-item-action">{{ account.storeName }}</a>
            </div>
          </div>
        </div>


      </div>
    </section>
    <section class="container d-flex flex-column justify-content-center align-items-center mt-4"
             v-if="reference && reference.processed">
      <h2 class="text-success mb-3">Payment Success!</h2>
      <span class="fs-5"><strong>Customer: {{ reference.debtor_name }}</strong></span>
      <span class="text-info">Amount Paid : {{ $utils.formatMoney(reference.amount) }}</span>
      <p class="card-text mb-4">We are happy to inform you that the payment for you software license was a success and
        your license has been extended successfully!</p>
      <span class="text-secondary">
        Click <a target="_blank"
                 :href="`https://chrilantech.com/LytSoftPosOnline/api/PDFReport/getInvoiceFromSaleNumber/${reference.salenum}/burntofi_chrilan`">here</a> to view your invoice
      </span>
    </section>
  </div>
</template>

<script>

import {responses} from "@/global-variables/responses";

export default {
  data() {
    return {
      paymentButtonVisible: true,
      showPaymentMethods: false,
      showReference: false,
      reference: null,
      kazangID: "",
      showKazangVendorModal: false,
      ref: this.$route.query.reference, // Initialize with your default value for 'ref'
      inputTextValue: "",
      loadingText: "Please Wait",
      paymentMethodsVisible: true,
      referenceTextVisible: false,
      paymentReference: "",
      showLoadingText: false, // New data property
      kazangAccounts: [],
      selectedKazanID: null,
      selectedPaymentMethod: null
    };
  },
  computed: {
    isPaid() {
      if (!this.reference) return false;
      return this.reference.processed;
    },

    pdfLink() {
      let prefix = "https://realtime.abc.co.zm:8443/PrintApi/Quotes/burntofi_chrilan/";
      return this.isPaid
          ? prefix + `invoice/${this.reference.invoice}`
          : prefix + `proforma/${this.reference.salenum}`;
    },
  },
  methods: {
    selectPaymentMethod(method) {
      switch (method) {
        case "EFT" :
          return this.handleEftButtonClick()
        case "Kazang" :
          return this.handleMobileMoneySubmit()
        case "Kazang VCD" :
          return this.fetchinfo()
      }
    },
    payByKazangVCD(kazangAccount) {
      this.showLoadingText = true
      return axios.get(API + `paymentCompleted/enterpriseVCD/${this.ref}?kazangID=${kazangAccount.kazangID}&storeDB=${kazangAccount.storeDB}`)
          .then(() => {
            responses.showSuccess(`Payment Success`)
            this.$router.go(0);
          }).catch((err) => errorHandler.tomcatError(err))
          .finally(() => this.showLoadingText = false);
    },
    fetchinfo() {
      this.showLoadingText = true
      this.selectedPaymentMethod = `Kazang VCD`
      return axios.get(API + "adminCustomerPanel/getKazangIDsByDebtor?debtorID=" + this.reference.debtor_id)
          .then(({data}) => this.kazangAccounts = data)
          .catch((err) => errorHandler.tomcatError(err))
          .finally(() => this.showLoadingText = false)
    },
    copyReference() {
      copy(this.reference.payment_reference)
      responses.showSuccess(`Reference Copied SuccessFully`)
    },
    handleEftButtonClick() {
      this.showLoadingText = true; // Show loading text when the button is clicked
      this.loadingText = "Please Wait";
      return axios.get(API + `eft?ref=${this.ref}`)
          .then(({data}) => {
            this.reference = data;
            this.selectedPaymentMethod = `EFT`
          }).catch((err) => errorHandler.tomcatError(err))
    },
    handleMobileMoneySubmit() {
      this.showLoadingText = true; // Show loading text when the button is clicked
      this.selectedPaymentMethod = "Kazang";
      let obj = {
        API_KEY: '945ac88d-e88a-11ed-91dd-00163e8d4996',
        amount: this.reference.amount,
        Service_Description: `LytSoft POS Subscription ( ${this.reference.debtor_name} )`,
        Client_Refrence: this.ref,
        Cancel_Url: 'https://gatewaykazang.chrilantech.com/test-failed',
        Sucess_Url: `https://subscription.chrilantech.com/`,
        // Sucess_Url: `https://subscription.chrilantech.com/mobile/payed`,
      };
      return axios.post(`https://realtime.abc.co.zm:8443/KazangPaymentGateway/api/GateWay/CreateTransaction`, obj)
          .then(({data}) => {
            if (data.Error) throw new Error(data)
            else {
              this.loadingText = 'Please Make Payment on the following Page...';
              return window.open(`https://gatewaykazang.chrilantech.com/kazangOnline?id=${data.ID}`, `_self`)
            }
          }).catch((err) => errorHandler.tomcatError(err))
    },
    handleShowPaymentMethods() {
      this.paymentButtonVisible = false;
      this.paymentMethodsVisible = true;
    },
    markAsViewed() {
      return axios.get(API + `viewed?ref=${this.ref}`)
          .catch((err) => errorHandler.tomcatError(err));
    },
    getReferenceData() {
      return axios.get(API + `adminCustomerPanel/getByReference?referenceID=` + this.ref)
          .then(({data}) => {
            this.reference = data
            if (data.payment_type) {
              this.selectedPaymentMethod = data.payment_type;
            }
          }).catch((err) => errorHandler.tomcatError(err))
    },

    kazangExtension() {
      this.showLoadingText = true; // Show loading text when the button is clicked
      this.paymentMethodsVisible = false;
      this.loadingText = "Updating your License , Please Wait...";
      let id = this.$route.query.ClientRef;
      if (id) {
        this.ref = id
        let obj = {
          id: id,
          payment_reference: this.$route.query.KazangRef,
          payment_type: "Kazang",
        }
        return this.getReferenceData().then(() => {
          if (this.reference.processed) return;
          return axios.post(API + `paymentCompleted/processManualPayment`, obj)
              .then(() => {
                return this.$router.replace({name: 'home', query: {reference: id}})
                    .then(() => window.location.reload(true));
              }).catch(err => errorHandler.tomcatError(err))
              .finally(() => this.showLoadingText = false)
        });
      }
    },

    initPage() {
      if (this.$route.query.ClientRef) {
        return this.kazangExtension();
      }
      this.showLoadingText = true;
      this.loadingText = `Getting Invoice Data ...`;
      return Promise.all([
        this.markAsViewed(),
        this.getReferenceData(),
      ]).finally(() => {
        this.showLoadingText = false;
        this.loadingText = "Please Wait...";
      })
    },
  },
  beforeMount() {
    this.initPage();
  },
};
</script>
<style>
.page {
  overflow-y: auto;
  height: 95%;
}
</style>